
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as cart49ktQJeNt_45nuXcLfTg0wibIlYyikUNFgmnb4bduCS9oMeta } from "/opt/buildhome/repo/pages/cart.vue?macro=true";
import { default as indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as loginKMdcZdjxVWBby7qzc8iax1m1i6js8gN3t8PvZM_RwR8Meta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as about_45abcJi0Tnwv_45HaqUDJZ9Nlw8qxiVAC0V1FQN5VYgZKQ6yagMeta } from "/opt/buildhome/repo/pages/about-abc.vue?macro=true";
import { default as indexU6cEdUwOQLooBNoHipDFIwHKwr15lDqyWvAADqcIobgMeta } from "/opt/buildhome/repo/pages/home/index.vue?macro=true";
import { default as _91state_93vOpCmme5WPHNtrzfdNyezO5y6ip9RlKK0MN1YjHsESwMeta } from "/opt/buildhome/repo/pages/home/[state].vue?macro=true";
import { default as how_45to_45orderWIRKarVhgrb7D_45eXd_dgYrw1c_aQI0pGY6HHT5XveLMMeta } from "/opt/buildhome/repo/pages/how-to-order.vue?macro=true";
import { default as terms_45of_45useoGLcwREWluCopSyrI_45CbbyannPtxLs2O1AKaKaL0EDgMeta } from "/opt/buildhome/repo/pages/terms-of-use.vue?macro=true";
import { default as accessibilityyawvagq6G_8Bn8lHmkyZr1IuKYJYDMWFD6Knc35rJt0Meta } from "/opt/buildhome/repo/pages/accessibility.vue?macro=true";
import { default as indexsSHEynImpmVJQBsgEWtpFbTIimvAlNbjHy0W37sieOIMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as indexXo0nFcMIUwsDnUC8cd13f7TsUlfoR3fOAhtG8cpf8jUMeta } from "/opt/buildhome/repo/pages/careers/index.vue?macro=true";
import { default as indexWXlBDXauKVZk5vmTli1AWv6UAnPyRZ2Hx0h2Z08h0BcMeta } from "/opt/buildhome/repo/pages/catalog/index.vue?macro=true";
import { default as indexZleC6gZUR1pA3AgN_tWZYx2uhxuaRYllFa1jADOL8zQMeta } from "/opt/buildhome/repo/pages/podcast/index.vue?macro=true";
import { default as indexi6XKsOVYkHB_45bJxY9VMOypIqxbmFjN6iM_rwKPs5qQsMeta } from "/opt/buildhome/repo/pages/articles/index.vue?macro=true";
import { default as indexvn8uJREEzhPVS50vQdfGdegfUqxSSsr3dwAC67VMrfMMeta } from "/opt/buildhome/repo/pages/checkout/index.vue?macro=true";
import { default as create_45accountjA5x_k52nni4_45RFWwe6v9ks7oEj1vjulByE_45BdN_JasMeta } from "/opt/buildhome/repo/pages/create-account.vue?macro=true";
import { default as privacy_45policyek63hKQ8ZMx2nIQV88L2_dad1Zv4m5Ym_n4RQ7NuSXAMeta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as index3xxHt9bk0QerByJNXPrS9ijmaorPYpVbp15BcHdsQBcMeta } from "/opt/buildhome/repo/pages/products/index.vue?macro=true";
import { default as reset_45password7TTpC6bsKAnTRrn0c2lyZltThWIyP9fDCYnH6eGiD0oMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as share_45wishlistP_455BB39lD0s9Idy0gZB_45nQn14_iKOMkW3mxQJ6oPUxgMeta } from "/opt/buildhome/repo/pages/share-wishlist.vue?macro=true";
import { default as detailsE_45AEcWu3Syi7E_45uaCmXP_nZ5QBZ8dwmydawA2MxX3JoMeta } from "/opt/buildhome/repo/pages/account/details.vue?macro=true";
import { default as _91state_9356x_4534irLTfJlpl7480f5n24bRWFX8ODyflJAHUh994Meta } from "/opt/buildhome/repo/pages/catalog/[state].vue?macro=true";
import { default as forgot_45passwordePKZgRXq0jrO1cJ61G6O25BiZx2cM1j2xdPJj4IzVnsMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as _91career_93ksipfewKSCk01wb533dLpMXL3qnvBk6mOrOog6HRGHoMeta } from "/opt/buildhome/repo/pages/careers/[career].vue?macro=true";
import { default as my_45ordersvfWyBpCpnaqqlTPnjPt7LANzd81w0BFA1CkZhF_450zvIMeta } from "/opt/buildhome/repo/pages/account/my-orders.vue?macro=true";
import { default as _91article_93rn8VflNpOqnrDryOqKtDz9Rri54YZWWJhG3Ded8JqpAMeta } from "/opt/buildhome/repo/pages/articles/[article].vue?macro=true";
import { default as _91_46_46_46slug_930_45O6WvK1peGjyJV0p4J96AmCAMd6xGNwdEWYru0ijeYMeta } from "/opt/buildhome/repo/pages/products/[...slug].vue?macro=true";
import { default as test_45auto_45completeBC6uTd7wIiBQQHRe3IdlIvFwCJHpy2i5IkG4ZN209IkMeta } from "/opt/buildhome/repo/pages/test-auto-complete.vue?macro=true";
import { default as my_45wishlistMuFDYhogHYeq2XQFr65cx5A7QL4eeabWyE2HpxNqLeIMeta } from "/opt/buildhome/repo/pages/account/my-wishlist.vue?macro=true";
import { default as submit_45a_45po31DAOeheeEWAibyfA_vFtcsalsZxwlv5ycgSX2q_45X4EMeta } from "/opt/buildhome/repo/pages/account/submit-a-po.vue?macro=true";
import { default as _91page_93wKmOSLfZqWrOYnANWstoCdan7sVwVrd_FRMNZ7KXzKgMeta } from "/opt/buildhome/repo/pages/articles/page/[page].vue?macro=true";
import { default as _91step_93RrCkeUbvO5FtY6_wEQgrueLxcRgVMrJtbEDURyz_N5kMeta } from "/opt/buildhome/repo/pages/checkout/step/[step].vue?macro=true";
import { default as _91_46_46_46slug_93zGoao_45uvd3M13_45jKBsMmb7WdtONVeUSZeW2CsHlskC8Meta } from "/opt/buildhome/repo/pages/free-trial/[...slug].vue?macro=true";
import { default as thank_45youiz5hrQ4Are1IDx7PcNrSsGMDc_6eAV6FhSw_456P5cEZMMeta } from "/opt/buildhome/repo/pages/free-trial/thank-you.vue?macro=true";
import { default as confirmationvsdlYpIt6eFDseyaTUGCeVHZxq9I7cp3iyVsKqp4TAgMeta } from "/opt/buildhome/repo/pages/checkout/confirmation.vue?macro=true";
import { default as documents_45for_45ordering6VYbjekbqpkQWv2cjZNB_45N51Up4_I42M_45lVBZBzkessMeta } from "/opt/buildhome/repo/pages/documents-for-ordering.vue?macro=true";
import { default as index5BoRuaDrJ7ujXRuis_v2_83Ld7NfFuNGYU0yuYgQVoIMeta } from "/opt/buildhome/repo/pages/products/[product]/index.vue?macro=true";
import { default as add_45payment_45method0oX3po0VfgoNn5Jv5JtL7yx3knCI0lzV5gIwK0h2We0Meta } from "/opt/buildhome/repo/pages/account/add-payment-method.vue?macro=true";
import { default as component_45stub1_45xbAQ_cwoTu4Vquus_iZrHE_45nDdxc12wg938hUZ_IUMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1_45xbAQ_cwoTu4Vquus_iZrHE_45nDdxc12wg938hUZ_IU } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "cart",
    path: "/cart",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginKMdcZdjxVWBby7qzc8iax1m1i6js8gN3t8PvZM_RwR8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "about-abc",
    path: "/about-abc",
    component: () => import("/opt/buildhome/repo/pages/about-abc.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: indexU6cEdUwOQLooBNoHipDFIwHKwr15lDqyWvAADqcIobgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/home/index.vue")
  },
  {
    name: "home-state",
    path: "/home/:state()",
    meta: _91state_93vOpCmme5WPHNtrzfdNyezO5y6ip9RlKK0MN1YjHsESwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/home/[state].vue")
  },
  {
    name: "how-to-order",
    path: "/how-to-order",
    meta: how_45to_45orderWIRKarVhgrb7D_45eXd_dgYrw1c_aQI0pGY6HHT5XveLMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/how-to-order.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/opt/buildhome/repo/pages/terms-of-use.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/opt/buildhome/repo/pages/accessibility.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    meta: indexXo0nFcMIUwsDnUC8cd13f7TsUlfoR3fOAhtG8cpf8jUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/careers/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/opt/buildhome/repo/pages/catalog/index.vue")
  },
  {
    name: "podcast",
    path: "/podcast",
    meta: indexZleC6gZUR1pA3AgN_tWZYx2uhxuaRYllFa1jADOL8zQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/podcast/index.vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexi6XKsOVYkHB_45bJxY9VMOypIqxbmFjN6iM_rwKPs5qQsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/articles/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/opt/buildhome/repo/pages/checkout/index.vue")
  },
  {
    name: "create-account",
    path: "/create-account",
    meta: create_45accountjA5x_k52nni4_45RFWwe6v9ks7oEj1vjulByE_45BdN_JasMeta || {},
    component: () => import("/opt/buildhome/repo/pages/create-account.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/opt/buildhome/repo/pages/products/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password7TTpC6bsKAnTRrn0c2lyZltThWIyP9fDCYnH6eGiD0oMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue")
  },
  {
    name: "share-wishlist",
    path: "/share-wishlist",
    component: () => import("/opt/buildhome/repo/pages/share-wishlist.vue")
  },
  {
    name: "account-details",
    path: "/account/details",
    component: () => import("/opt/buildhome/repo/pages/account/details.vue")
  },
  {
    name: "catalog-state",
    path: "/catalog/:state()",
    component: () => import("/opt/buildhome/repo/pages/catalog/[state].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordePKZgRXq0jrO1cJ61G6O25BiZx2cM1j2xdPJj4IzVnsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue")
  },
  {
    name: "careers-career",
    path: "/careers/:career()",
    meta: _91career_93ksipfewKSCk01wb533dLpMXL3qnvBk6mOrOog6HRGHoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/careers/[career].vue")
  },
  {
    name: "account-my-orders",
    path: "/account/my-orders",
    component: () => import("/opt/buildhome/repo/pages/account/my-orders.vue")
  },
  {
    name: "articles-article",
    path: "/articles/:article()",
    component: () => import("/opt/buildhome/repo/pages/articles/[article].vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/products/[...slug].vue")
  },
  {
    name: "test-auto-complete",
    path: "/test-auto-complete",
    component: () => import("/opt/buildhome/repo/pages/test-auto-complete.vue")
  },
  {
    name: "account-my-wishlist",
    path: "/account/my-wishlist",
    component: () => import("/opt/buildhome/repo/pages/account/my-wishlist.vue")
  },
  {
    name: "account-submit-a-po",
    path: "/account/submit-a-po",
    component: () => import("/opt/buildhome/repo/pages/account/submit-a-po.vue")
  },
  {
    name: "articles-page-page",
    path: "/articles/page/:page()",
    meta: _91page_93wKmOSLfZqWrOYnANWstoCdan7sVwVrd_FRMNZ7KXzKgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/articles/page/[page].vue")
  },
  {
    name: "checkout-step-step",
    path: "/checkout/step/:step()",
    component: () => import("/opt/buildhome/repo/pages/checkout/step/[step].vue")
  },
  {
    name: "free-trial-slug",
    path: "/free-trial/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/free-trial/[...slug].vue")
  },
  {
    name: "free-trial-thank-you",
    path: "/free-trial/thank-you",
    component: () => import("/opt/buildhome/repo/pages/free-trial/thank-you.vue")
  },
  {
    name: "checkout-confirmation",
    path: "/checkout/confirmation",
    component: () => import("/opt/buildhome/repo/pages/checkout/confirmation.vue")
  },
  {
    name: "documents-for-ordering",
    path: "/documents-for-ordering",
    component: () => import("/opt/buildhome/repo/pages/documents-for-ordering.vue")
  },
  {
    name: "products-product",
    path: "/products/:product()",
    component: () => import("/opt/buildhome/repo/pages/products/[product]/index.vue")
  },
  {
    name: "account-add-payment-method",
    path: "/account/add-payment-method",
    component: () => import("/opt/buildhome/repo/pages/account/add-payment-method.vue")
  },
  {
    name: component_45stub1_45xbAQ_cwoTu4Vquus_iZrHE_45nDdxc12wg938hUZ_IUMeta?.name,
    path: "/account/",
    component: component_45stub1_45xbAQ_cwoTu4Vquus_iZrHE_45nDdxc12wg938hUZ_IU
  }
]