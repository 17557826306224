import payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_ZW1o8HvOQrB2QQ_DQ4lKzEruMplK6Pc3T_HLPklHWfs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NG916udKkc5WzTwbWdisT8xNC5ql_dI3N3psxyDqxgY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3_zjQH5mHUKaC6k4dE1maAyxye91CRnCrYDt1OCd0UM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_sa9WWycor9AwnVB_F9m6aoR0Gx9qlWZGAdaiGtGGgck from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FwaYVzltvoYJ0OlsqoX_AEVfWImDfhDMrdC7FiB_n5k from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ybJT1pI7PrOKBs6pJSU2Res3lnrcTRbrTvuGFblRA4s from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_MLaZ_Xqz_DCAmtxF_DWrhXxIYGAdb_y2PwDcnO1HGvA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_hwJb1MY4IJsuaY_o9LJ_vHfgooWhsrqt18pI_arTaQQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.24.0_jiti@2.4_4f2398669681af4c7a5d9d09c2afb809/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_JHJoHgyi1ndXkW7cyXhp8s3VvT9x5xL07Gyn_HlYBqc from "/opt/buildhome/repo/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_a082fab3541c270f4388bee84711a3dd/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/opt/buildhome/repo/plugins/error-handler.ts";
import floating_vue_IzZvwu4d0V2PmLzX5FM8OeN_n9KoM824GlcNgFB4ru0 from "/opt/buildhome/repo/plugins/floating-vue.ts";
import sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM from "/opt/buildhome/repo/plugins/sentry.ts";
import vueGoogleMaps_K6sv2NTkUHNA0vyej32YAi_299wZ2p0OeyH69GqhbYE from "/opt/buildhome/repo/plugins/vueGoogleMaps.ts";
export default [
  payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw,
  revive_payload_client_ZW1o8HvOQrB2QQ_DQ4lKzEruMplK6Pc3T_HLPklHWfs,
  unhead_NG916udKkc5WzTwbWdisT8xNC5ql_dI3N3psxyDqxgY,
  router_3_zjQH5mHUKaC6k4dE1maAyxye91CRnCrYDt1OCd0UM,
  _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM,
  payload_client_sa9WWycor9AwnVB_F9m6aoR0Gx9qlWZGAdaiGtGGgck,
  navigation_repaint_client_FwaYVzltvoYJ0OlsqoX_AEVfWImDfhDMrdC7FiB_n5k,
  check_outdated_build_client_ybJT1pI7PrOKBs6pJSU2Res3lnrcTRbrTvuGFblRA4s,
  chunk_reload_client_MLaZ_Xqz_DCAmtxF_DWrhXxIYGAdb_y2PwDcnO1HGvA,
  plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_hwJb1MY4IJsuaY_o9LJ_vHfgooWhsrqt18pI_arTaQQ,
  plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4,
  plugin_JHJoHgyi1ndXkW7cyXhp8s3VvT9x5xL07Gyn_HlYBqc,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  floating_vue_IzZvwu4d0V2PmLzX5FM8OeN_n9KoM824GlcNgFB4ru0,
  sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM,
  vueGoogleMaps_K6sv2NTkUHNA0vyej32YAi_299wZ2p0OeyH69GqhbYE
]